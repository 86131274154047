<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import {swalHelper} from "@/helpers/swal-helper";
import Swal from "sweetalert2";

/**
 * Admin Parameters Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      parameters: [],

      visibleModal: false,
      editingParameter: null
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.parameters.title'),
          active: true
        }
      ]
    },

    async loadParameters() {
      try {
        const {data} = await axios.get(`/parameter`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.parameters = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    openEditParameterModal(parameter) {
      this.editingParameter = Object.assign({}, parameter)
      this.visibleModal = true
    },

    hideEditParameterModal() {
      this.visibleModal = false
      this.editingParameter = null
    },

    editParameter() {
      swalHelper.yesOrNoCustomizable(() => {
        const json = JSON.stringify({
          "value": this.editingParameter.value,
          "parameterType": this.editingParameter.type
        });

        axios.post(`/parameter`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        }).then(() => {
          const { type } = this.editingParameter
          if (type === "META_TITLE" || type === "META_DESCRIPTION" || type === "META_IMAGE") {
            this.updateMetaTags()
          }

          this.hideEditParameterModal()
          Swal.fire("Sukces!", "Parametr został zaktualizowany!", "success")
              .then(() => this.loadParameters())
        }).catch((error) => {
          errorCatcher.catchErrors(error);
        })
      }, "warning", "Czy na pewno?", `Parametr zostanie zaktualizowany`, "Zaakceptuj", "Anuluj", "btn btn-success", "btn btn-danger")
    },

    updateMetaTags() {
      axios.post(`/meta-tags/update`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    }

  },

  async created() {
    await this.loadParameters()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.parameters.title')" :items="getItems()" />
    <div class="row">
      <div class="col-3" />
      <div class="col-6">
        <div class="card">
          <div class="card-body">

            <div v-for="(parameter, index) in parameters" :key="index" class="mb-4">
              <label>{{ $t(`parameter.${parameter.type.replaceAll("_", ".").toLowerCase()}`) }}</label> <b-button @click="openEditParameterModal(parameter)" variant="success" class="btn-sm"><i class="mdi mdi-pencil"/></b-button>
              <input :value="parameter.value" class="form-control" disabled />
            </div>

          </div>
        </div>
      </div>

      <div class="col-3" />
    </div>

    <b-modal
        id="modal-1"
        v-model="visibleModal"
        v-if="editingParameter"
        title="Edycja parametru"
        title-class="font-18"
        hide-footer
        @hide="hideEditParameterModal"
        @esc="hideEditParameterModal">

      <div class="form-group">
        <label>{{ $t(`parameter.${editingParameter.type.replaceAll("_", ".").toLowerCase()}`) }}</label>

        <template v-if="editingParameter.valueType === 'TEXT'">
          <input v-model="editingParameter.value" type="text" class="form-control" />
        </template>
        <template v-else-if="editingParameter.valueType === 'DOUBLE' || editingParameter.valueType === 'FLOAT'">
          <input v-model.number="editingParameter.value" type="number" class="form-control custom-number-with-gap" min="5" step="0.1" />
        </template>
        <template v-else-if="editingParameter.valueType === 'INTEGER'">
          <input v-model.number="editingParameter.value" type="number" class="form-control" />
        </template>
      </div>

      <div class="text-right">
        <b-button @click="editParameter" variant="success">{{ $t('message.save') }}</b-button>
        <b-button class="ml-1" variant="danger" @click="hideEditParameterModal">{{  $t('message.cancel') }}</b-button>
      </div>
    </b-modal>
  </Layout>
</template>